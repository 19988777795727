# # определение размеров и ориентации экрана
	#
	# использование :
  #   myfunc(scr_units())
  #		variable = scr_units()
	#
scr_units = ()->
	#
	screen = {
		# ширина окна браузера
		'win_width': window.innerWidth,
		# высота окна браузера
		'win_height': window.innerHeight,
		# ширина документа
		'doc_width': document.body.clientWidth,
		# высота документа
		'doc_height': document.body.clientHeight,
	}
	# высота части страницы что не поместилась в окно браузера
	screen.beyond = screen.doc_height - screen.win_height
	#
	# определение ориентации экрана
	# [значение (1.3) здесь потому что при приближении соотношений сторон к квадрату неверно вычисляются размеры элементов]
	if (screen.docwidth / screen.docheight) >= 1.3
		screen.orient = 'gorizontal'
	else
		screen.orient = 'vertical'
	#
	# возвращение результата в место вызова функции
	return screen
# ==========================================

# # определение скролла страницы
	#
	# использование :
  #   scroll_position()
	#
scroll_position = ()->
	scroltop = $(window).scrollTop()
	#
	return scroltop
# ==========================================

# # получение основных элементов страницы
	#
	# использование :
  #   myfunc(main_ids())
  #		myfunc(main_ids().svera)
  #		variable = main_ids()
  #
main_ids = ->
	ids = {
		'svera':	$('#svera')
		'landing': $('#landing', @svera)
		'cardsmenu': $('#cardsmenu', @landing)
		'footer':	$('footer', @svera)
	}
	#
	return ids
# ==========================================

# # установка|удаление класса
	#
	# использование :
  #   classes(mainids().svera, class1, class2)
	#
classes = (element, removeclass, addclass) ->
		element.removeClass(removeclass).addClass(addclass)
# ==========================================

# # прозрачность|непрозрачность
	#
	# использование :
  #   dense(element)		|| непрозрачность
  #		opacity(element)	|| прозрачность
	#
dense = (element) ->
		element.removeClass('прозрачный').addClass('непрозрачный')
#
opacity = (element) ->
		element.removeClass('непрозрачный').addClass('прозрачный')
# ==========================================
